import React from 'react'
import { Layout, UserContext } from '../../components/Layout'
import { EditForm, DefaultFormValues } from '../../components/issues/EditForm'
import { updateIssue, getIssue, Issue } from '../../api/cms'
import { WindowLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { getQueryVariable, handleErrorFromAPI, loginRedirect } from '../../utils'
import { ErrorNotification } from '../../components/Notification'
import { Grid, Cell } from 'baseui/layout-grid'

interface LayoutEditIssueProps {
  location: WindowLocation
}

function confirmUpdateIssue(id: number, data: Issue, file: File | null, token: string | null) {
  return updateIssue(id, data, file, token).then(() => navigate('/issues/issue-list'))
}

export const EditIssue = (props: LayoutEditIssueProps) => {
  const issueId = getQueryVariable('id', props.location.search)
  const focusSend = getQueryVariable('focusSend', props.location.search) === 'true'
  const [defaults, setDefaults] = React.useState<DefaultFormValues>()
  const [loadingErrorActive, setLoadingErrorActive] = React.useState(false)
  const { token, setUserContext } = React.useContext(UserContext)

  React.useEffect(() => {
    if (!token) {
      loginRedirect()
    }
    if (!issueId) {
      navigate('/404', { replace: true })
    }

    if (token && issueId) {
      getIssue(token, issueId)
        .then((response: Issue) => {
          const category = response.category_id ? (response.category as string) : 'Other'
          setDefaults({
            id: issueId,
            issueName: response.name,
            location: response.location || '',
            date: response.end_date
              ? [new Date(response.start_date), new Date(response.end_date)]
              : [new Date(response.start_date)],
            dateRange: response.end_date !== null,
            time: response.start_time
              ? new Date(new Date(1970, 1, 1).toDateString() + ' ' + response.start_time)
              : null,
            ongoing: response.ongoing || false,
            notes: response.notes || '',
            category: [{ label: category, id: response.category_id?.toString() }],
            resolved: response.resolved || false,
            hasPhoto: typeof response.photo !== 'undefined' && response.photo !== null
          })
        })
        .catch(error => handleErrorFromAPI(error, setUserContext, setLoadingErrorActive))
    }
  }, [token, issueId, setUserContext])

  return (
    <>
      <Grid>
        <Cell span={[4, 6]}>
          <ErrorNotification hasError={loadingErrorActive} />
        </Cell>
      </Grid>
      {!loadingErrorActive && (
        <EditForm
          saveFunc={confirmUpdateIssue.bind(null, issueId)}
          query={props.location.search}
          defaults={defaults}
          showWarningOnImageUpdate={true}
          focusSend={focusSend}
        />
      )}
    </>
  )
}

const LayoutEditIssue = (props: LayoutEditIssueProps) => {
  return (
    <Layout>
      <EditIssue location={props.location} />
    </Layout>
  )
}

export default LayoutEditIssue
